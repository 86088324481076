<template>
  <div :class="versionTwo ? 'w-full' : ''">
    <div
      v-if="!versionTwo"
      class="relative"
    >
      <input
        ref="combobox"
        v-model="inputValue"
        :readonly="readonly"
        :required="required"
        :placeholder="placeholder"
        type="text"
        :class="extraOptions"
        class="w-[341px] h-[36px] !mb-0 !rounded !border border-gray-300 !bg-gray-50 !py-2.5 !pl-8 !pr-12
              text-gray-900 focus-custom"
        role="combobox"
        aria-controls="options"
        aria-expanded="false"
        @blur="comboboxClose"
        @focus="comboboxToggle"
      >

      <input type="hidden" name="company_hotel" :value="selectedName">
      <input type="hidden" name="hotel"
        :value="hotelNameInput === 'json' ? selectedHotelJson : (selected.name ? selected.name : inputValue)">
      <input :value="selected.city" id="city" type="hidden" name="hotel[city]">
      <input :value="selected.name" id="name" type="hidden" name="hotel[name]">
      <input :value="selected.location_id" id="name" type="hidden" name="hotel[location_id]">
      <input :value="selected.country" id="country" type="hidden" name="hotel[country]">
      <input :value="selected.country_iso_code" id="country_iso_code" type="hidden" name="hotel[country_iso_code]">
      <input :value="selected.state" id="state" type="hidden" name="hotel[state]">
      <input :value="selected.region" id="region" type="hidden" name="hotel[region]">
      <input :value="selected.company" id="company" type="hidden" name="hotel[company]">
      <input :value="selected.phone" id="phone" type="hidden" name="hotel[phone]">
      <input :value="selected.company_website" id="company_website" type="hidden" name="hotel[company_website]">

      <button
        type="button"
        class="pr-[20px] absolute inset-y-0 left-0 flex items-center rounded-r-md px-2 focus:outline-none additional-information-search-button">
        <i class="fas fa-search search-input-hotel"></i>
      </button>
      <div
        v-if="!addhotelModal"
        role="listbox"
        tabindex="0"
        class="listbox-container w-full"
      >
        <ul
          class="absolute z-10 mt-1 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          :class="{
            'hidden': !isVisible,
            'max-h-100': options.length === 0,
            'max-h-60': options.length > 0
          }"
        >
          <div
            v-cloak
            v-if="options.length === 0"
            class="text-sm ps-3 pe-[19px] pt-[22px] pb-[21px] text-black"
          >
            <p class="font-medium mb-4 ms-[11px]">
              Uh oh…looks like we couldn’t find any hotels by that name
            </p>
            <div class="bg-[#F2F2F2] pt-3.5 pb-[26px] px-[18px]">
              <p class="font-medium mb-[9px]">
                Tips
              </p>
              <div>
                <p class="flex items-baseline mb-[11px]">
                  <i
                    class="fas fa-circle me-[5px] text-[8px]"
                    :style="{ color: primaryColor ? primaryColor : '#3445C2' }"
                  />
                  <span class="font-normal">
                    Try searching for your hotel by its name on Tripadvisor
                  </span>
                </p>
                <p class="flex items-baseline">
                  <i
                    class="fas fa-circle me-[5px] text-[8px]"
                    :style="{ color: primaryColor ? primaryColor : '#3445C2' }"
                  />
                  <span class="font-normal">
                    Work for a hotel portfolio or chain? Search for a single hotel in your portfolio that you want to lookup and analyze
                  </span>
                </p>
              </div>
            </div>
          </div>
          <template>
            <li
              v-for="(item, index) in displayedOptions"
              :key="index"
              class="relative cursor-default select-none !py-2 !pl-3 !pr-9 text-gray-900 hover:bg-indigo-600 hover:text-white !h-[56px]"
              tabindex="-1"
              @click="selectItem(item)"
            >
              <!-- Selected: "font-semibold" -->
              <span
                class="!block !truncate !mb-0 "
                :class="selected === item ? '!font-semibold' : '!font-normal'"
              >
                {{ item[label] }}
              </span>
              <p class="!text-[12px] !text-[#A1A1A1]">
                {{ (item && item.city) ? item.city : '' }} ({{ (item && item.country) ? item.country : '' }})
              </p>
              <span
                v-if="selected === item"
                class="!absolute !inset-y-0 !right-0 !flex !items-center !pr-4 !text-indigo-600"
              >
                <svg
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
            </li>
            <div
              v-if="displayedOptions.length > 0"
              class="cursor-pointer menu-extra-options"
              @mousedown.prevent="toggleShowAllResults"
            >
              <button
                type="button"
                class="my-2 ml-3 w-[38px] h-[38px] bg-[#F2F2F2] rounded border border-gray-300 bg-gray-50 additional-information-button">
                <i class="far fa-search"></i>
              </button>
              <span class="extra-options-hotel !ml-[10px] !font-medium">
                {{ showAllResults ? 'Show less' : `See all results for '${inputValue ? inputValue : ''}'` }}
              </span>
            </div>
            <div
              class="cursor-pointer menu-extra-options"
              @click="addhotelModal = true"
            >
              <button
                type="button"
                class="my-2 ml-3 w-[38px] h-[38px] bg-[#F2F2F2] rounded border border-gray-300 bg-gray-50 additional-information-button">
                <i class="far fa-plus-square"></i>
              </button>
              <span class="extra-options-hotel !ml-[10px] !font-medium">
                Add a missing hotel
              </span>
            </div>
          </template>
        </ul>
      </div>
      <div
        v-else
        class="absolute z-10 mt-[1px] bg-[#F6F8FF] shadow-lg rounded-b-lg listbox-container px-[10px] w-full min-w-[273px]"
      >
        <hotel-attribute-modal
          :primary-color="primaryColor"
          :hotel-name="inputValue"
          @close-modal="addhotelModal = false; isVisible = true"
          @created-hotel="newHotelCreated"
        />
      </div>
    </div>
    <div
      v-else
      class="relative z-[2]"
    >
      <input
        ref="combobox"
        v-model="inputValue"
        :readonly="readonly"
        :required="required"
        :placeholder="placeholder"
        type="text"
        :class="[
          extraOptions,
          isVisible ? '!rounded-t-lg !rounded-b-none !shadow-none !border-b-[0.5px]' : '!rounded-lg  !bg-transparent cursor-pointer'
        ]"
        class="input-hotel-search sm:text-lg w-full z-[2] !pl-[55px] focus:ring-0 bg-[#FFFFFF] h-[66px] !border-[#E2E2E2] hover:shadow-[#E3E3E3] shadow-[#EAEAEA] hover:shadow-[0_2px_9px_0_rgba(0,0,0,0.1)] shadow-[0_2px_4px_0_rgba(0,0,0,0.1)] !rounded bg-white"
        role="combobox"
        aria-controls="options"
        aria-expanded="false"
        @blur="comboboxClose"
        @focus="comboboxToggle"
      >

      <input type="hidden" name="company_hotel" :value="selectedName">
      <input type="hidden" name="hotel" :value="hotelNameInput === 'json' ? selectedHotelJson : (selected.name ? selected.name : inputValue)">
      <input :value="selected.city" id="city" type="hidden" name="hotel[city]">
      <input :value="selected.name" id="name" type="hidden" name="hotel[name]">
      <input :value="selected.location_id" id="name" type="hidden" name="hotel[location_id]">
      <input :value="selected.country" id="country" type="hidden" name="hotel[country]">
      <input :value="selected.country_iso_code" id="country_iso_code" type="hidden" name="hotel[country_iso_code]">
      <input :value="selected.state" id="state" type="hidden" name="hotel[state]">
      <input :value="selected.region" id="region" type="hidden" name="hotel[region]">
      <input :value="selected.company" id="company" type="hidden" name="hotel[company]">
      <input :value="selected.phone" id="phone" type="hidden" name="hotel[phone]">
      <input :value="selected.company_website" id="company_website" type="hidden" name="hotel[company_website]">

      <button
        type="button"
        class="absolute inset-y-0 left-0 flex items-center rounded-r-md focus:outline-none h-inset ps-[24px] pe-[15px] h-[66px]"
        @click="focusSelect"
      >
        <i
          id="search-input-icon"
          class="fas fa-search font-normal text-[22px] text-[#B4B3B3] z-[2]"
        />
      </button>
      <button
        v-if="!isVisible"
        class="absolute flex justify-center items-center px-2 rounded-lg text-white text-base font-semibold w-[199px] h-[51.67px] inset-y-0 right-[8px] self-center bg-darken"
        :style="{ backgroundColor: primaryColor ? primaryColor : '#3445C2' }"
        @click="focusSelect"
      >
        Generate free report
      </button>
      <div
        v-if="isVisible && !addhotelModal"
        role="listbox"
        tabindex="0"
        class="relative z-10 mt-[1px] w-full bg-white shadow-lg rounded-b-lg listbox-container"
      >
        <ul
          class="rounded-b-lg py-1 trip-advisor-scrollbar  overflow-auto"
          :class="{
            'hidden': !isVisible,
            '!max-h-[290px]': displayedOptions.length >= 5,
            '!max-h-60': displayedOptions.length < 5
          }"
        >
          <div
            v-if="displayedOptions.length === 0"
            class="text-sm ps-3 pe-[19px] pt-[22px] pb-[21px] text-black"
          >
            <p class="font-medium mb-4 ms-[11px]">
              Uh oh…looks like we couldn’t find any hotels by that name
            </p>
            <div class="bg-[#F2F2F2] pt-3.5 pb-[26px] ps-[18px]">
              <p class="font-medium mb-[9px]">
                Tips
              </p>
              <div>
                <p class="flex items-baseline mb-[11px]">
                  <i
                    class="fas fa-circle me-[5px] text-[8px]"
                    :style="{ color: primaryColor ? primaryColor : '#3445C2' }"
                  />
                  <span class="font-normal">
                    Try searching for your hotel by its name on Tripadvisor
                  </span>
                </p>
                <p class="flex items-baseline">
                  <i
                    class="fas fa-circle me-[5px] text-[8px]"
                    :style="{ color: primaryColor ? primaryColor : '#3445C2' }"
                  />
                  <span class="font-normal">
                    Work for a hotel portfolio or chain? Search for a single hotel in your portfolio that you want to lookup and analyze
                  </span>
                </p>
              </div>
            </div>
          </div>
          <template>
            <li
              v-for="(item, index) in displayedOptions"
              :key="index"
              class="flex relative cursor-pointer select-none ps-[15px] pe-[22px] text-gray-900 !h-[56px] hover:bg-[#F7F7F7] items-center justify-between"
              tabindex="-1"
              @mouseenter="hoveredIndex = index"
              @mouseleave="hoveredIndex = null"
              @click="selectItem(item)"
            >
              <div class="flex">
                <div class="flex-grow">
                  <span
                    class="!block !truncate !mb-[3px] !font-semibold text-sm leading-4"
                  >
                    {{ item[label] }}
                  </span>
                  <p class="!text-[12px] !text-[#A1A1A1] !font-medium leading-3">
                    {{ (item && item.city) ? item.city : '' }} ({{ (item &&
                      item.country) ? item.country : '' }})
                  </p>
                </div>
                <span
                  v-if="selected === item"
                  class="text-indigo-600 ms-[5px]"
                >
                  <svg
                    class="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </div>
              <button
                v-if="hoveredIndex === index"
                class="flex justify-center items-center px-[18px] rounded-lg text-white font-semibold h-[37px] text-sm bg-darken"
                :style="{ backgroundColor: primaryColor ? primaryColor : '#3445C2' }"
              >
                Select
              </button>
            </li>
          </template>
        </ul>
        <div
          v-if="isVisible && versionTwo && displayedOptions.length > 0"
          class="cursor-pointer"
          @mousedown.prevent="toggleShowAllResults"
        >
          <button
            type="button"
            class="ml-3 mb-[24px] mt-[35px] w-[38px] h-[38px] bg-[#F2F2F2] rounded border border-gray-300 bg-gray-50"
          >
            <i class="far fa-search"></i>
          </button>
          <span class="!extra-options-hotel !ml-[10px] !font-medium">
            {{ showAllResults ? 'Show less' : `See all results for '${inputValue}'` }}
          </span>
        </div>
        <div
          class="cursor-pointer"
          @click="addhotelModal = true"
        >
          <button
            type="button"
            class="ml-3 mb-[23px] w-[38px] h-[38px] bg-[#F2F2F2] rounded border border-gray-300 bg-gray-50"
          >
            <i class="far fa-plus-square"></i>
          </button>
          <span class="!extra-options-hotel !ml-[10px] !font-medium">
            Add a missing hotel
          </span>
        </div>
      </div>
      <div
        v-if="isVisible && addhotelModal"
        class="absolute z-10 mt-[1px] w-full bg-[#F6F8FF] shadow-lg rounded-b-lg listbox-container"
      >
        <hotel-attribute-modal
          :primary-color="primaryColor"
          :hotel-name="inputValue"
          @close-modal="addhotelModal = false; isVisible = true"
          @created-hotel="newHotelCreated"
        />
      </div>
    </div>
    <div
      v-if="isVisible && versionTwo"
      class="fixed top-0 left-0 w-[150rem] h-[150rem] bg-black bg-opacity-50 z-[1] pointer-events-none"
    />
  </div>
</template>
<script>
import HotelAttributeModal from '../../htr/components/trip-advisor/HotelAttributeModal.vue';

export default {
  components: {
    HotelAttributeModal
  },
  props: {
    value: {
      type: [Object, String],
      default: function () {
        return {};  // or return [] for an Array
      }
    },
    label: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'Search for a hotel'
    },
    hotelNameInput: {
      type: String,
      default: 'json'
    },
    options: {
      type: Array,
      default: () => []
    },
    extraOptions: {
      type: String,
      default: 'bg-gray-50'
    },
    customValue: { // For now its for reviews to allow custom hotel name only uses on write a review form
      type: Boolean,
      default: false
    },
    versionTwo: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      inputValue: this.value ? this.value[this.label] ? this.value[this.label] : this.value.name : '',
      selected: {},
      isVisible: false,
      timer: null,
      showAllResults: false,
      preventClose: false,
      primaryColor: '#3445C2',
      hoveredIndex: null,
      addhotelModal: false
    }
  },
  computed: {
    displayedOptions() {
      return this.showAllResults ? this.options : this.options.slice(0, 5);
    },
    selectedName() {
    return this.selected ? this.selected.name : '';
  },
    selectedHotelJson() {
      return this.selected ? JSON.stringify(this.selected) : '';
    }
  },
  watch: {
    inputValue(newVal) {

      if (this.timer) {
        clearTimeout(this.timer);
      }
      if (this.customValue && (Object.keys(this.selected).length <= 1 || newVal !== this.value?.name)) {
        this.$emit('input', { name: newVal });
      }
      this.timer = setTimeout(() => {
        if (newVal && newVal.trim() && (newVal !== this.value?.name || (this.customValue && Object.keys(this.selected).length <= 1))) {
          this.$emit('search-change', newVal);
          this.isVisible = !this.readonly;
        } else {
          this.isVisible = false;
        }

        if (newVal && newVal.length === 0) {
          this.selected = {};
          this.$emit('empty', null);
        }
      }, 500);
    },
    value(value) {
      this.selected = value
    },
    selected(value) {
      this.inputValue = value ? value[this.label] : ''
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside, true);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside, true);
  },
  methods: {

    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        if (this.preventClose) {
          this.preventClose = false;
        } else if (this.isVisible) {
          this.isVisible = false;
        }
      }
    },
    selectItem(item) {
      this.selected = item
      this.isVisible = false;
      this.$emit('input', item)
    },

    comboboxToggle() {
      if (this.readonly) {
        return
      }

      this.$refs['combobox'].focus()
      this.isVisible = !this.isVisible
    },
    toggleShowAllResults() {
      this.showAllResults = !this.showAllResults;

    },
    comboboxClose() {
      if (this.preventClose) {
        this.preventClose = false;
        return;
      }
      if (this.timer) {
        clearTimeout(this.timer)
      }

      if (!this.isVisible) {
        return
      }

      this.timer = setTimeout(() => {
        this.isVisible = false
      }, 200)
    },
    focusSelect() {
      document.querySelector('.input-hotel-search').focus()
    },
    newHotelCreated(hotel) {
      this.addhotelModal = false
      this.$emit('newHotelSelected', hotel)
    }
  }
}
</script>

<style lang="scss" scoped>
.search-input-hotel {
  color: #B4B3B3;
  font-size: 14px;
}
.extra-options-hotel {
  color: #000000;
  font-size: 14px;
}
.focus-custom {
  font-size: 14px !important;
  line-height: 24px !important;
}
.focus-custom:focus {
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
  box-shadow: 0 0 0 2px #5b67ca !important;
}
.input-hotel-search:hover + .absolute #search-input-icon{
  color: #3445C2 !important;
}
.trip-advisor-scrollbar {
  &::-webkit-scrollbar-track {
    border-radius: 0;
    background-color: #E7E7E7;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #E7E7E7;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #D8D8D8;
  }
}
.bg-darken {
  transition: background-color 0.3s;
}

.bg-darken:hover {
  filter: brightness(90%);
}

[v-cloak] {
  display: none;
}
</style>
